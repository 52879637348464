import {
  Component,
  ElementRef,
  inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
// import { Modal } from 'bootstrap';
import { BootstrapService } from '../../lib/services/bootstrap.service';
import { DialogRef } from './dialog-ref';

interface BackdropInternal {
  _config: {
    className: string;
    isAnimated: boolean;
    isVisible: boolean;
    rootElement: Element | string;
  };
  _element: HTMLElement;
  _getConfig(config: any): any;
}

interface ModalInternal {
  _config: any; // Modal.Options;
  _backdrop: BackdropInternal;
}

/**
 * Bootstrap 모달 기본 z-index
 */
const MODAL_Z_INDEX = 1055;

/**
 * Dialog Component 추상 클래스
 *
 * @usageNotes
 * Dialog 구현 시 template 에는 `modal-dialog` 클래스가 설정된 요소가 최상위 레벨에 있어야 함.
 * ```html
 * <div class="modal-dialog ...">
 *    <!-- 표시할 내용 -->
 * </div>
 * ```
 */
@Component({
  template: '',
})
export abstract class DialogAbstract implements OnInit, OnDestroy {
  /**
   * Bootstrap Modal
   */
  modal?: any; // Modal;

  /**
   * Dialog 참조
   *
   * Dialog 생성 시 자동 설정됨.
   */
  @Input() dialogRef!: DialogRef<any>;

  private bootstrapService: BootstrapService;

  constructor(protected elementRef: ElementRef<HTMLElement>) {
    this.bootstrapService = inject(BootstrapService);
  }

  ngOnInit(): void {
    if (this.dialogRef) {
      // Bootstrap Modal 동작을 위해 설정,
      // Angular HostBinding 으로는 ngOnInit 단계에서 설정되지 않아 Element 직접 접근
      this.elementRef.nativeElement.className = 'modal fade';

      const { nativeElement } = this.elementRef;

      nativeElement.style.zIndex = `${MODAL_Z_INDEX + this.dialogRef.id}`;

      this.dialogRef.element = nativeElement;

      this.modal = this.bootstrapService.getModal(
        nativeElement,
        this.dialogRef.options,
      );

      this.dialogRef.modal = this.modal;

      this.initBackdrop();

      this.addModalEventListener();

      if (!this.dialogRef.hidden) {
        this.modal?.show();
      }
    }
  }

  ngOnDestroy(): void {
    if (!this.dialogRef) {
      return;
    }

    this.dialogRef.modal = null!;
    this.modal?.dispose();
  }

  /**
   * Dialog 토글
   */
  toggle(): void {
    if (!this.dialogRef) {
      return;
    }

    this.modal?.toggle();
  }

  /**
   * Dialog 닫기
   */
  close(result?: any): void {
    if (!this.dialogRef) {
      return;
    }

    this.dialogRef.result = result;
    this.modal?.hide();
  }

  /**
   * Backdrop 직접 설정
   *
   * 중첩해서 사용할 수 있도록 함
   */
  private initBackdrop(): void {
    const internal = this.modal as unknown as ModalInternal;

    // Backdrop 이 표시될 위치 변경
    internal._backdrop._config.rootElement = '.dialog-wrapper';

    // Backdrop 설정 업데이트
    internal._backdrop._config = internal._backdrop._getConfig(
      internal._backdrop._config,
    );

    const backdrop = document.createElement('div');
    backdrop.className = internal._backdrop._config.className;
    backdrop.style.zIndex = `${MODAL_Z_INDEX + this.dialogRef.id - 1}`;
    if (internal._backdrop._config.isAnimated) {
      backdrop.classList.add('fade');
    }

    internal._backdrop._element = backdrop;
  }

  /**
   * Bootstrap Modal 이벤트 등록
   */
  private addModalEventListener(): void {
    if (!this.dialogRef) {
      return;
    }

    const { nativeElement } = this.elementRef;

    nativeElement.addEventListener('show.bs.modal', (e) => {
      if (this.dialogRef.isBlockShowing) {
        e.preventDefault();
        return;
      }

      // 결과 초기화
      this.dialogRef.result = null;
      this.dialogRef.onDialogOpening.next(this);
      this.dialogRef.onDialogOpening.complete();
    });

    nativeElement.addEventListener('shown.bs.modal', () => {
      this.dialogRef.onDialogOpened.next(this);
      this.dialogRef.onDialogOpened.complete();
    });

    nativeElement.addEventListener('hide.bs.modal', () => {
      this.dialogRef.onDialogClosing.next(this.dialogRef.result);
      this.dialogRef.onDialogClosing.complete();
    });

    nativeElement.addEventListener('hidden.bs.modal', () => {
      this.dialogRef.onDialogClosed.next(this.dialogRef.result);
      this.dialogRef.onDialogClosed.complete();
    });

    nativeElement.addEventListener('hidePrevented.bs.modal', () => {
      this.dialogRef.onDialogClosePrevented.next(this.dialogRef.result);
    });
  }
}
